import Swal from 'sweetalert2';
import util from '../util';

/**
 * Update client.
 *
 * @param {Object} _context Store context
 * @param {Object} client Client data
 * @return {Promise}
 */
export default async (context, client) => {
  const { state, commit, getters } = context;
  const { fetchEndpoint, fetchToken, validateStatus } = util;
  const { region, environment } = getters;

  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  try {
    const data = {
      name: client.name,
      environment: client.environment,
      bundleName: client.bundleName,
      databaseCreds: client.databaseCreds,
      misc: client.misc,
      tasks: client.tasks,
    };

    const { status } = await axios({
      method: 'patch',
      url: `${endpoint}/update/${client.token}`,
      validateStatus,
      data,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }
    commit('showClientEditingDialog', false);

    const clients = state.clients.splice(0);
    const clientIndex = clients.findIndex((c) => c.token === client.token);

    clients[clientIndex] = client;
    commit('setClients', clients);

    await util.toast('success', `${client.name} updated successfully`);
  } catch (error) {
    if (error.response && error.response.data.message === 'Unauthorized') {
      Swal.fire(
        'Unauthorized',
        'Sorry, you are not allowed to update client data.',
        'error'
      );
    } else {
      console.error(error.message);
      Swal.fire(
        'Error',
        'Unable to update client data. Contact the administrator.',
        'error'
      );
    }
  }
};
