<template>
  <div class="content" :style="{
    'border-left': getBorderSetting(this.environment, this.microservicesLoading),
  }">
    <v-card>
      <v-card dark tile :class="{ warn: showProdWarning, main: !showProdWarning }">
        <v-card-title style="" class="d-flex justify-center main__title">
          <span class="toolbar"> Deployed Microservices: {{ prettifiedRegion }} - {{ prettifiedEnv }}</span>
          <v-spacer />
          <v-spacer />
          <v-card dark tile :class="{ warn: showProdWarning, main: !showProdWarning }">
          </v-card>
          <v-btn class="fab toolbar" outlined fab small @click="getMicroservices">
            <v-icon color="#fff">mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
      <v-data-table :headers="headers" :items="microservices" :loading="microservicesLoading" :items-per-page="15">
        <template #item.CreationTime="{ item }">
          <span>{{ new Date(item.CreationTime).toUTCString() }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { regions, environments } from '../store/actions/auth/pretty-data';

export default {
  name: 'MicroservicesTable',
  // Component data
  data() {
    return {
      headers: [
        {
          text: 'Stack Name',
          align: 'start',
          sortable: true,
          value: 'StackName',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'StackStatus',
        },
        {
          text: 'Created Time',
          align: 'start',
          sortable: true,
          value: 'CreationTime',
        },

        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'TemplateDescription',
        },
      ],
      options: {},
    };
  },

  // Computed props
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'region',
      'environment',
    ]),

    ...mapState([
      'microservices',
      'microservicesLoading',
    ]),

    showProdWarning() {
      return this.environment == 'prod' && this.microservicesLoading === false;
    },

    prettifiedRegion() {
      return regions[this.region];
    },

    prettifiedEnv() {
      return environments[this.environment];
    },

  },

  // On component creation
  created() {
    // Refresh data every 2 minutes
    setInterval(() => {
      // this.$store.state.autoRefresh = true;
      this.autorefreshMicroservices();
      this.getMicroservices();
    }, 120000);
  },

  // Methods
  methods: {
    ...mapActions([
      'getMicroservices',
    ]),

    ...mapMutations([
      'autorefreshMicroservices',
    ]),

    formatDate(timestamp) {
      const localizedFormat = require('dayjs/plugin/localizedFormat');
      dayjs.extend(localizedFormat);

      return `${dayjs.unix(timestamp).format('lll')}`;
    },

    getBorderSetting(env, isLoading) {
      const color = {
        prod: '#aB2330',
        demo: '#fb8c00',
        staging: '#4caf50',
      };

      return isLoading ? 'none' : `5px solid ${color[env]}`;
    },

  },
};
</script>

<style lang="scss">
.main {
  background: #0f5384 !important;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 18px 16px 16px;
    width: 100%;
  }
}

.toolbar {
  margin-top: 2vh;
}
</style>