import axios from 'axios';
import Vue from 'vue';
import store from './store/index';
import App from './App.vue';

// router setup
import router from './routes/router';

// Plugins
import vuetify from './plugins/vuetify';

window.axios = axios;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  vuetify,
  store,
});
