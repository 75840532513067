import Swal from 'sweetalert2';
import util from '../util';

/**
 * Update platform.
 *
 * @param {Object} context Store context
 * @param {String} client Client
 * @return {Promise}
 */
export default async (context, client) => {
  const { dispatch, getters } = context;
  const { fetchEndpoint, fetchToken, fetchEmail, validateStatus } = util;
  const { region, environment } = getters;

  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);
  const user = fetchEmail(accessToken);

  try {
    const { status } = await axios({
      method: 'post',
      url: `${endpoint}/platform/update`,
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
      data: {
        user,
        env: client.environment,
        clientToken: client.token,
        project: client.project,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    await util.toast(
      'success',
      `Update request has been sent to "${client.name}"`
    );

    await dispatch('getClients');
  } catch (error) {
    console.error(error.message);

    if (error.response) {
      if (error.response.data.message === 'Unauthorized') {
        Swal.fire(
          'Unauthorized',
          'Sorry, you are not allowed to update this platform.',
          'error'
        );
      } else {
        console.error(error.message);
        Swal.fire('Error', error.response.data.message, 'error');
      }
    } else {
      console.error(error.message);
      Swal.fire(
        'Error',
        'Unable to send the update request. Contact the administrator.',
        'error'
      );
    }
  }
};
