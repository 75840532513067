module.exports = {
  /* Available regions */
  regions: {
    'eu-west-1': 'Europe',
    'us-east-1': 'United States',
  },
  /* Available environments */
  environments: {
    staging: 'Staging',
    demo: 'Demo',
    prod: 'Production',
  },
  /* Google Data Studio reports */
  analyticsSources: [
    {
      text: 'Frontend',
      value:
        'https://datastudio.google.com/embed/reporting/6c2a5a02-3e6d-483a-a470-53689bf1ccbe/page/qD2KC',
    },
    {
      text: 'Backend',
      value:
        'https://datastudio.google.com/embed/reporting/62e1ba83-6e4d-407c-9acf-29715ef860f7/page/qD2KC',
    },
  ],
};
