import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations/index';
import actions from './actions/index';
import getters from './getters';
import state from './state';

Vue.use(Vuex);

export default new Vuex.Store({
  mutations,
  actions,
  getters,
  state,
});
