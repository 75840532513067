<template>
  <div class="wrapper">
    <div
      class="swal2-icon swal2-question swal2-icon-show"
      style="display: flex"
    >
      <div class="swal2-icon-content">?</div>
    </div>
    <div class="content">
      Type in the new {{ selectedProject }} branch for <br />{{
        project.name
      }}
      (<b>{{ project.environment }})</b>
    </div>
    <v-text-field
      v-model="branchName"
      :rules="rules"
      label="Branch Name"
      placeholder="hotfix-LAB-13307"
      outlined
      required
    ></v-text-field>
    <div class="buttons">
      <v-btn
        class="ma-2"
        dark
        x-large
        color="primary"
        @click.prevent="emitBranch(branchName)"
      >
        Confirm
      </v-btn>
    </div>
    <div class="footer">
      <v-btn
        class="ma-2"
        color="secondary"
        dark
        x-large
        @click.prevent="emitBranch('master')"
      >
        Switch to master
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedProject: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      branchName: '',
      rules: [(v) => (v.length ? true : 'Please type in a branch name')],
    };
  },
  methods: {
    emitBranch(value) {
      this.$emit('proceed', value);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang='scss'>
.v-text-field {
  width: 70%;
}
.wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3rem;
  justify-content: center;
  .content {
    height: auto;
    margin: 1rem;
    text-align: center;
  }
  .options {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 0.5rem 0.8rem;
    button {
      width: 100px;
      margin: 0 2rem;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
  }
}
</style>