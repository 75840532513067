import setMicroservices from './set';
import resetMicroservices from './reset';
import autorefreshMicroservices from '../autorefresh';
import microservicesLoading from './loading';

export default {
  setMicroservices,
  resetMicroservices,
  autorefreshMicroservices,
  microservicesLoading,
};
