<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
html,
body {
  overflow: auto !important;
  font-family: 'Roboto', sans-serif;
}

code::before,
code::after {
  display: none !important;
}
</style>
