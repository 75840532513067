<template>
  <div class="wrapper">
    <v-btn
      v-if="isAuthenticated"
      v-show="!$vuetify.breakpoint.mdAndUp"
      color="blue"
      dark
      fixed
      bottom
      right
      fab
      @click.stop="showDrawer = !showDrawer"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <sk-navigation-drawer 
      v-if="isAuthenticated"
      :show-drawer="showDrawer"
      @close:drawer="showDrawer = $event"
    />
    <div :class="isAuthenticated ? 'main-panel' : ''" :style="!isAuthenticated ? 'width:100%' : ''">
      <dashboard-content />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

import SkNavigationDrawer from "@/components/SkNavigationDrawer.vue";
import DashboardContent from "./Content.vue";

export default {
  name: 'DashboardLayout',

  components: {
    DashboardContent,
    SkNavigationDrawer,
  },
  data() {
    return {
      showDrawer: false,
    };
  },
  computed: mapGetters(['isAuthenticated'])
};
</script>

<style lang="scss">
.main-panel {
  padding-left: 0;

  @media screen and (min-width: 960px) {
    padding-left: 256px;
  }
}

.width-full {
  width: 100%;
}
</style>