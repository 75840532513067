import getClients from './list';
import deleteClient from './delete';
import createClient from './create';
import updateClient from './update';
import exportClients from './export';

export default {
  getClients,
  deleteClient,
  createClient,
  updateClient,
  exportClients,
};
