<template>
  <v-row>
    <v-dialog v-model="showDomainsDialog" max-width="600px">
      <v-card>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="domainHeaders"
                  :items="domains"
                  :loading="domainsLoading"
                  item-key="slug"
                >
                  <template #top>
                    <v-toolbar flat>
                      <v-toolbar-title>Domains</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>
                      <!-- editing dialog -->
                      <v-dialog v-model="dialog" max-width="500px">
                        <template #activator="{ on, attrs }">
                          <v-btn
                            color="blue darken-1"
                            text
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <label>
                                    <b>Domain type</b>
                                  </label>
                                  <v-select
                                    v-model="domain.type"
                                    value="domain.type"
                                    :items="domainTypes"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <b>FQDNs</b>
                                  <p>
                                    Make sure to press Enter after adding a
                                    domain.
                                  </p>
                                  <v-combobox
                                    v-model="domain.fqdns"
                                    multiple
                                    chips
                                  />
                                </v-col>
                                <v-col>
                                  <div v-if="domain.type === 'tenant'">
                                    <label>
                                      <b>Tenant name</b>
                                    </label>
                                    <v-text-field
                                      v-model="domain.name"
                                      value="domain.name"
                                      placeholder="A tenant"
                                    />
                                  </div>
                                  <div v-if="domain.type === 'redirect'">
                                    <label>
                                      <b>Redirect to</b>
                                    </label>
                                    <v-text-field
                                      v-model="domain.redirect"
                                      value="domain.redirect"
                                      placeholder="www.example.com"
                                    />
                                  </div>
                                  <div
                                    v-if="domain.type !== 'redirect'"
                                    class="row"
                                  >
                                    <template>
                                      <v-checkbox
                                        v-model="domain.saml"
                                        :label="`SAML authentication (SSO)`"
                                      />
                                      <v-icon
                                        small
                                        class="mr-2"
                                        @click="toggleSAMLDialogModal(true)"
                                      >
                                        mdi-pencil
                                      </v-icon>
                                    </template>

                                    <v-checkbox
                                      v-model="domain.httpAuth.enabled"
                                      :label="`Password protection (HTTP Auth)`"
                                      cols="12"
                                    />
                                  </div>
                                  <div v-else>
                                    Please create an A record pointing to this
                                    IP before saving:
                                    <b>{{ getRedirectIP() }}</b>
                                  </div>
                                  <v-col
                                    v-if="domain.httpAuth.enabled"
                                    cols="12"
                                  >
                                    <p>
                                      Please note these credentials are not
                                      meant for security purposes.<br />Disable
                                      this as soon as the platform goes live.
                                    </p>
                                    <label>
                                      <b>Username</b>
                                    </label>
                                    <v-text-field
                                      v-model="domain.httpAuth.username"
                                      value="domain.httpAuth.username"
                                    />
                                    <label>
                                      <b>Password</b>
                                    </label>
                                    <v-text-field
                                      v-model="domain.httpAuth.password"
                                    />
                                  </v-col>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDomain"
                            >
                              Cancel
                            </v-btn>
                            <v-btn color="red darken-1" text @click="remove">
                              Delete
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="SAMLDialog" max-width="500px">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">SAML IdP Configuration</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <label>
                                    <b>Entity ID</b>
                                  </label>
                                  <v-text-field
                                    v-model="domain.idpConfig.entityId"
                                    value="domain.idpConfig.entityId"
                                    placeholder="Entity ID"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <label>
                                    <b>Assertion Consumer URL</b>
                                  </label>
                                  <v-text-field
                                    v-model="domain.idpConfig.endpoint"
                                    value="domain.idpConfig.endpoint"
                                    placeholder="Assertion Consumer URL"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <label>
                                    <b>Single Logout Service</b>
                                  </label>
                                  <v-text-field
                                    v-model="
                                      domain.idpConfig.singleLogoutService
                                    "
                                    value="domain.idpConfig.singleLogoutService"
                                    placeholder="Single Logout Service"
                                  />
                                </v-col>
                                <v-col>
                                  <v-textarea
                                    v-if="domain.idpConfig.publicCert"
                                    v-model="domain.idpConfig.publicCert"
                                    value="domain.idpConfig.publicCert"
                                    :disabled="
                                      domain.idpConfig.publicCert !== null
                                    "
                                  />
                                  <v-btn
                                    v-else
                                    color="blue darken-1"
                                    text
                                    @click="generateCertificate"
                                  >
                                    {{ certificateTitle }}
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="toggleSAMLDialogModal(false)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="red darken-1"
                              text
                              @click="removeIdpConfig"
                            >
                              Delete
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editDomain(item)">
                      mdi-pencil
                    </v-icon>
                  </template>

                  <template #[`item.type`]="{ item }">
                    <span
                      >{{ item.type[0].toUpperCase() + item.type.slice(1) }}
                    </span>
                  </template>

                  <template #[`item.fqdns`]="{ item }">
                    <a
                      style="color: #2680c3"
                      :href="'https://' + item.fqdns[0]"
                      target="_blank"
                      ><b>{{ item.fqdns[0] }}</b></a
                    >

                    <span
                      v-if="item.fqdns.length > 1"
                      :id="`item-urls-${item.slug}`"
                      class="item-urls"
                    >
                      and
                      <span class="dotted-span" @click="editDomain(item)"
                        >{{ item.fqdns.length - 1 }} more</span
                      >
                    </span>
                  </template>

                  <template #[`item.redirect`]="{ item }">
                    <a
                      v-if="item.redirect"
                      style="color: #2680c3"
                      :href="'https://' + item.redirect"
                      target="_blank"
                      ><b>{{ item.redirect }}</b></a
                    >
                  </template>

                  <template #[`item.httpAuth`]="{ item }">
                    <v-icon v-if="item.httpAuth.enabled" color="#08b000"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else color="#ff0000">mdi-minus</v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '../../event-bus';

export default {
  name: 'DomainsDialog',

  data() {
    return {
      editIndex: -1,
      clientToken: null,
      domain: this.getNewDomain(),
      domainTypes: [
        { text: 'Main', value: 'main' },
        { text: 'Tenant', value: 'tenant' },
        { text: 'Redirect', value: 'redirect' },
      ],
      dialog: false,
      SAMLDialog: false,
      dialogDelete: false,
    };
  },

  computed: {
    domainHeaders() {
      return [
        {
          text: 'Domain type',
          align: 'center',
          value: 'type',
          sortable: true,
        },
        { text: 'FQDNs', value: 'fqdns', sortable: true },
        // if no redirect domain in this.domains then dont show this column
        {
          text: this.domains.find((d) => d.type === 'redirect')
            ? 'Redirect to'
            : '',
          value: 'redirect',
          sortable: false,
        },
        { text: 'HTTP Auth', value: 'httpAuth', sortable: false },
        { value: 'actions', sortable: false },
      ];
    },
    ...mapGetters({
      domains: 'domains',
      domainsLoading: 'domainsLoading',
      open: 'showDomainsDialog',
      region: 'region',
    }),

    showDomainsDialog: {
      get() {
        return this.open;
      },

      set(val) {
        this.$store.state.showDomainsDialog = val;
        return val;
      },
    },

    formTitle() {
      return this.editIndex === -1 ? 'New domain' : 'Edit domain';
    },
    certificateTitle() {
      return this.domain.publicCert
        ? this.domain.publicCert
        : 'Generate certificate';
    },
  },

  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDomain();
    },
    SAMLDialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.toggleSAMLDialogModal(false);
    },
  },

  created() {
    EventBus.$on('toggleEdit', (token) => {
      this.clientToken = token;
      this.getDomains(token);
    });
  },

  methods: {
    ...mapActions([
      'getDomains',
      'editDomain',
      'createDomain',
      'updateDomain',
      'deleteDomain',
      'deleteIdPConfig',
      'generateIdpCertificate',
      'getIdpCertificate',
    ]),
    getNewDomain() {
      return {
        type: 'tenant',
        fqdns: [],
        name: null,
        slug: null,
        redirect: null,
        httpAuth: {
          enabled: false,
          username: null,
          password: null,
        },
        saml: false,
        idpConfig: {
          entityId: '',
          endpoint: '',
          singleLogoutService: '',
          publicCert: '',
        },
      };
    },

    close() {
      this.$store.commit('showDomainsDialog', false);
    },

    async save() {
      const params = { domain: this.domain, clientToken: this.clientToken };

      if (this.editIndex === -1) {
        this.createDomain(params);
      } else {
        this.updateDomain({ ...params, slug: this.domain.slug });
      }

      this.toggleSAMLDialogModal(false);
      this.closeDomain();
    },

    async generateCertificate() {
      const params = { clientToken: this.clientToken, slug: this.domain.slug };
      this.generateIdpCertificate(params);
      this.toggleSAMLDialogModal(false);
      this.closeDomain();
    },

    async remove() {
      return Swal.fire({
        title: 'Delete this domain?',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: '#e64942',
      }).then(async (res) => {
        if (res.isConfirmed) {
          this.closeDomain();

          await this.deleteDomain({
            slug: this.domain.slug,
            clientToken: this.clientToken,
          });
        }
      });
    },

    async removeIdpConfig() {
      return Swal.fire({
        title: 'Delete this IdP configuration?',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: '#e64942',
      }).then(async (res) => {
        if (res.isConfirmed) {
          this.toggleSAMLDialogModal(false);
          this.closeDomain();

          await this.deleteIdPConfig({
            slug: this.domain.slug,
            clientToken: this.clientToken,
            domain: this.domain,
            removeIdpConfigParam: true,
          });
        }
      });
    },

    editDomain(item) {
      this.editIndex = this.domains.indexOf(item);
      this.domain = { ...item };
      this.dialog = true;
    },

    closeDomain() {
      this.dialog = false;

      this.$nextTick(() => {
        this.editIndex = -1;
        this.domain = this.getNewDomain();
      });
    },

    getRedirectIP() {
      const region = this.region.split('-')[0];

      switch (region) {
      case 'eu':
        return '52.213.221.52';
      case 'us':
        return '3.210.167.41';
      default:
        return 'missing';
      }
    },

    toggleSAMLDialogModal(open = true) {
      this.SAMLDialog = open;
    },
  },
};
</script>

<style></style>
