import showClientCreationDialog from './set-show-client-creation-dialog';
import showClientEditingDialog from './set-show-client-editing-dialog';
import showDomainsDialog from './set-show-domains-dialog';
import setClients from './set';
import resetClientsOptions from './reset';
import setResultsPage from './set-results-page';
import setSearchKeywords from './set-keywords';

export default {
  showClientCreationDialog,
  showClientEditingDialog,
  showDomainsDialog,
  setClients,
  resetClientsOptions,
  setResultsPage,
  setSearchKeywords,
};
