<template>
  <v-row justify="center">
    <v-dialog v-model="showClientCreationDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Create client</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <label>
                    <b>Name</b>
                  </label>
                  <v-text-field
                    v-model="form.name"
                    :rules="requiredRules"
                    placeholder="My client"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Internal name</b>
                  </label>
                  <p>Short name used internally.</p>
                  <v-text-field
                    v-model="form.internalName"
                    :rules="requiredRules"
                    placeholder="E.g. intesa-techmp"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Style Bundle</b>
                  </label>
                  <p>Bundle name used internally.</p>
                  <v-text-field
                    v-model="form.bundleName"
                    :rules="requiredRules"
                    placeholder="E.g. default_branch"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Main domain</b>
                  </label>
                  <p>
                    Please add the region suffix when using an internal
                    SkipsoLabs subdomain.
                    <br />e.g. platform.<b>eu</b>.skipsolabs.com
                  </p>
                  <v-text-field
                    v-model="form.mainDomain"
                    placeholder="platform.eu.skipsolabs.com"
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Miscellaneous</b>
                  </label>
                  <v-checkbox
                    v-model="form.customDb"
                    item-text="name"
                    item-value="value"
                    :label="`Custom DB`"
                    required
                  />
                  <v-col v-if="form.customDb" cols="12">
                    <label>
                      <b>DB hostname</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.host"
                      :rules="requiredRules"
                      placeholder="mydatabase.id.region.rds.amazonaws.com"
                      required
                    />
                    <label>
                      <b>DB username</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.user"
                      :rules="requiredRules"
                      placeholder="dbusername"
                      required
                    />

                    <label>
                      <b>DB password</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.password"
                      :type="'text'"
                      :rules="requiredRules"
                      placeholder="dbpassword"
                      required
                    />

                    <label>
                      <b>DB name</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.name"
                      :rules="requiredRules"
                      placeholder="dbname"
                      required
                    />
                  </v-col>
                  <v-checkbox
                    v-model="form.saml"
                    item-text="name"
                    item-value="value"
                    :label="`SAML authentication (SSO)`"
                    required
                  />
                  <v-checkbox
                    v-model="form.misc.msActive"
                    item-text="name"
                    item-value="value"
                    :label="`Microservices`"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Background tasks</b>
                  </label>
                  <v-checkbox
                    v-model="form.tasks.scheduling"
                    item-text="name"
                    item-value="value"
                    :label="`Scheduling`"
                  />
                  <v-checkbox
                    v-model="form.tasks.recommendations"
                    item-text="name"
                    item-value="value"
                    :label="`Recommendations`"
                    required
                  />
                  <v-checkbox
                    v-model="form.tasks.gds.enabled"
                    item-text="name"
                    item-value="value"
                    :label="`Google Sheets sync`"
                  />
                  <v-checkbox
                    v-model="form.tasks.taskManager"
                    item-text="name"
                    item-value="value"
                    :label="`Task Manager Expiring Tasks Notifications`"
                  />
                  <v-col v-if="form.tasks.gds.enabled">
                    <label>
                      <b>Sync to Google each:</b>
                    </label>
                    <v-select
                      v-model="form.tasks.gds.syncInterval"
                      :items="taskIntervals"
                      label="Select an interval"
                      single-line
                    ></v-select>
                  </v-col>
                  <v-checkbox
                    v-model="form.tasks.tempUserRemoval"
                    item-text="name"
                    item-value="value"
                    :label="`Temp users removal (weekly)`"
                    required
                  />
                  <v-checkbox
                    v-model="form.tasks.salesforce"
                    item-text="name"
                    item-value="value"
                    :label="`Salesforce sync`"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="create">Create</v-btn>
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CreateClientDialog',

  data() {
    return {
      valid: true,
      requiredRules: [(v) => !!v || 'Field is required'],
      form: {
        name: '',
        databaseCreds: { host: null, user: null, password: null, name: null },
        customDb: false,
        mainDomain: null,
        bundleName: '',
        saml: false,
        misc: {
          msActive: true,
        },
        tasks: {
          taskManger: false,
          recommendations: false,
          tempUserRemoval: false,
          gds: {
            enabled: false,
            syncInterval: '15m',
          },
        },
      },
      taskIntervals: [
        { text: '1 minute', value: '1m' },
        { text: '15 minutes', value: '15m' },
        { text: '30 minutes', value: '30m' },
        { text: '1 hour', value: '1hr' },
        { text: '2 hours', value: '2hr' },
        { text: '6 hours', value: '6hr' },
        { text: '12 hours', value: '12hr' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      environment: 'environment',
      open: 'showClientCreationDialog',
    }),

    showClientCreationDialog: {
      get() {
        return this.open;
      },

      set(val) {
        this.$store.state.showClientCreationDialog = val;
        return val;
      },
    },
  },

  methods: {
    ...mapActions(['createClient']),

    async create() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        await this.createClient(this.form);

        this.form = {
          name: '',
          environment: 'staging',
          customDb: false,
          databaseCreds: { host: null, user: null, password: null, name: null },
          mainDomain: '',
          misc: {
            msActive: true,
          },
          tasks: {
            taskManger: false,
            recommendations: false,
            tempUserRemoval: false,
            gds: {
              enabled: false,
              syncInterval: '15m',
            },
            salesforce: false,
          },
          platformInfo: {
            branch: null,
            gitCommit: null,
            updatedAt: null,
            version: null,
          },
          bundleName: '',
          stylesInfo: {
            branch: null,
            gitCommit: null,
            updatedAt: null,
          },
        };
      }
    },

    close() {
      this.$store.commit('showClientCreationDialog', false);
    },
  },
};
</script>

<style>
</style>
