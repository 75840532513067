import {
  regions,
  environments,
  analyticsSources,
} from './actions/auth/pretty-data';

export default {
  /* Constants */
  regions,
  environments,
  analyticsSources,
  resultsPerPage: 8,

  /* Selected region (either 'eu-west-1' or 'us-east-1') */
  region: localStorage.getItem('clientsDashboardRegion') || 'eu-west-1',

  /* Selected stage (staging/demo/prod) */
  environment: localStorage.getItem('clientsDashboardEnvironment') || 'staging',

  /* Available clients */
  clients: [],

  /* True when loading from API */
  clientsLoading: false,

  /* Available microservices */
  microservices: [],

  /* True when loading from API */
  microservicesLoading: false,

  /* Available domains */
  domains: [],

  /* True when loading from API */
  domainsLoading: false,

  autoRefresh: false,

  /* Results page */
  resultsPage: 1,

  /* Indicates if we fetched all the available clients */
  endOfResults: false,

  /* Search keywords */
  searchKeywords: '',

  /* Show client creation dialog */
  showClientCreationDialog: false,

  /* Show client editing dialog */
  showClientEditingDialog: false,

  /* Show domains editing dialog */
  showDomainsDialog: false,

  /* Latest platform code info from GitHub (version, commit, etc..) */
  platformInfo: {},

  /* True when loading from API */
  platformInfoLoading: false,

  /* Indicates whether we are signing in */
  signingIn: false,

  /* GDS selected analytics per app */
  selectedAnalytics: analyticsSources[0].value,
};
