<template>
  <sk-modal
    component-id="1"
    :escape-button-close="true"
    :backdrop="true"
    @close="close"
  >
    <div class="modal-header">
      <button
        class="modal-back"
        @click.prevent="modalSteps > 0 ? modalSteps-- : (modalSteps = 0)"
      >
        <v-icon v-if="modalSteps" large> mdi-arrow-left </v-icon>
      </button>
      <button class="modal-close" @click.prevent="close">
        <v-icon large> mdi-close </v-icon>
      </button>
    </div>
    <UpdatePlatformStep1
      v-if="modalSteps === 0"
      :update-platform="updatePlatform"
      :branches="branches"
      :project="project"
      @proceed="selectProject"
    />
    <UpdatePlatformStep2
      v-if="modalSteps === 1"
      :project="project"
      :update-platform="updatePlatform"
      :selected-project="selectedProject"
      :branch="selectedBranch"
      @proceed="updateProject"
      @close="close"
    />
  </sk-modal>
</template>

<script>
import SkModal from '@skipsolabs/vue-library/src/components/SkModal/SkModal.vue';
import UpdatePlatformStep1 from './Modal.step1.vue';
// step 2 does not exist so we will call step 2 a step 3
import UpdatePlatformStep2 from './Modal.step3.vue';

export default {
  components: {
    UpdatePlatformStep1,
    UpdatePlatformStep2,
    SkModal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalSteps: 0,
      selectedProject: null,
      selectedBranch: '',
      updatePlatform: true,
      branches: [
        {
          label: 'Platform',
          color: 'primary',
          value: 'platform',
        },
        {
          label: 'Styles',
          color: 'primary',
          value: 'styles',
        },
      ],
    };
  },
  methods: {
    selectBranch(val) {
      this.selectedBranch = val;
      this.modalSteps = 2;
    },
    selectProject(val) {
      this.selectedProject = val;
      this.modalSteps = 1;
    },
    updateProject() {
      const obj = {
        name: this.selectedProject,
      };
      this.$emit('updateProject', obj);
      this.close();
    },
    close() {
      this.$emit('close');
      this.modalSteps = 0;
    },
  },
};
</script>

<style>
</style>