import VueRouter from 'vue-router';
import Vue from 'vue';
import routes from './routes';
import store from '@/store/index';

const { isAuthenticated } = store.getters;

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: 'nav-item active',
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Sign in' && to.name !== 'OAuth' && !isAuthenticated) {
    next({ name: 'Sign in' });
  } else next();

  if (to.path === '/' && isAuthenticated) {
    next({ name: 'Clients' });
  } else next();
});

Vue.use(VueRouter);

export default router;
