import Swal from 'sweetalert2';

export default async (context, { accessToken, region, environment }) => {
  const { commit, state } = context;

  state.signingIn = true;

  try {
    commit('setAccessToken', { accessToken });
    // TODO: Add proper support to the refresh token
    // commit('setRefreshToken', { refreshToken: data.refreshToken });
    commit('setClientConfig', { region, environment });

    state.signingIn = false;
    location.href = '/clients';
  } catch (err) {
    state.signingIn = false;
    console.error(err.message);

    if (!/Network error/.test(err.message)) {
      Swal.fire('Error', err.message, 'error');
    }
  }
};
