import Swal from 'sweetalert2';
import util from '../util';

/**
 * Create domain config for a specific client.
 *
 * @param {Object} _context Store context
 * @param {Object} payload Client token and domain data
 * @return {Promise}
 */
export default async (context, payload) => {
  const { clientToken, domain } = payload;

  const { dispatch, getters } = context;
  const { fetchEndpoint, validateStatus, fetchToken } = util;
  const { region, environment } = getters;

  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  // Slug is generated by the API
  delete payload.domain.slug;

  try {
    const res = await axios({
      method: 'post',
      url: `${endpoint}/domains/create`,
      data: {
        clientToken,
        domain,
      },
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    const { status } = res;
    const respData = res.data;

    if (status === 401 || status === 403) {
      return;
    }

    await dispatch('getDomains', clientToken);
    await dispatch('getClients');

    await util.toast(
      'success',
      `'${respData.slug}' domain created successfully`
    );
  } catch (error) {
    if (error.response.data.message === 'Unauthorized') {
      Swal.fire(
        'Unauthorized',
        'Sorry, you are not allowed to create a domain.',
        'error'
      );
      return;
    }

    Swal.fire('Error', error.response.data.message, 'error');
  }
};
