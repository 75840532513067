import Swal from 'sweetalert2';
import util from '../util';

/**
 * Create client.
 *
 * @param {Object} _context Store context
 * @param {Object} client Client data
 * @return {Promise}
 */
export default async (context, client) => {
  const { dispatch, commit, getters } = context;
  const { fetchEndpoint, validateStatus, fetchToken } = util;
  const { region, environment } = getters;

  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  client.environment = environment;

  try {
    const { status } = await axios({
      method: 'post',
      url: `${endpoint}/create`,
      data: client,
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    await util.toast('success', `${client.name} created successfully`);

    commit('showClientCreationDialog', false);
    await dispatch('getClients');
  } catch (error) {
    if (error.response && error.response.data.message === 'Unauthorized') {
      Swal.fire(
        'Unauthorized',
        'Sorry, you are not allowed to create a client.',
        'error'
      );
    } else {
      console.error(error.message);
      Swal.fire(
        'Error',
        'Unable to create a client. Contact the administrator.',
        'error'
      );
    }
  }
};
