import setAccessToken from './set-access-token';
import setRefreshToken from './set-refresh-token';
import setClientConfig from './set-client-config';
import resetClientConfig from './reset';
import setRegion from './set-region';
import setEnvironment from './set-environment';
import setPlatformInfo from './set-repo-info';
import setSelectedAnalytics from './set-selected-analytics';

export default {
  setAccessToken,
  setRefreshToken,
  setClientConfig,
  resetClientConfig,
  setRegion,
  setEnvironment,
  setPlatformInfo,
  setSelectedAnalytics,
};
