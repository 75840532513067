export default {
  /* Available regions */
  regions(state) {
    return state.regions;
  },

  /* Available environments */
  environments(state) {
    return state.environments;
  },

  /* Check if the current user is authenticated */
  isAuthenticated() {
    return localStorage.getItem('accessToken') !== null;
  },

  /* Check if clients data is auto refreshing */
  isAutoRefreshing(state) {
    return state.autoRefresh;
  },

  /* Returns the selected region */
  region(state) {
    return state.region;
  },

  /* Returns the selected stage */
  environment(state) {
    return state.environment;
  },

  resultsPage(state) {
    return state.resultsPage;
  },

  searchKeywords(state) {
    return state.searchKeywords;
  },

  endOfResults(state) {
    return state.endOfResults;
  },

  /* Available clients */
  clients(state) {
    return state.clients;
  },

  clientsLoading(state) {
    return state.clientsLoading;
  },

  /* Show client creation dialog */
  showClientCreationDialog(state) {
    return state.showClientCreationDialog;
  },

  /* Show client editing dialog */
  showClientEditingDialog(state) {
    return state.showClientEditingDialog;
  },

  domains(state) {
    return state.domains;
  },

  domainsLoading(state) {
    return state.domainsLoading;
  },

  /* Show domains editing dialog */
  showDomainsDialog(state) {
    return state.showDomainsDialog;
  },

  resultsPerPage(state) {
    return state.resultsPerPage;
  },

  signingIn(state) {
    return state.signingIn;
  },

  /* Returns the latest platform info from GitHub */
  platformInfo(state) {
    return state.platformInfo;
  },

  platformInfoLoading(state) {
    return state.platformInfoLoading;
  },
};
