import Swal from 'sweetalert2';
import util from '../util';

/**
 * Update domain config for a specific client.
 *
 * @param {Object} _context Store context
 * @param {Object} payload Client token and domain data
 * @return {Promise}
 */
export default async (context, payload) => {
  const { clientToken, slug, domain } = payload;

  const { getters, dispatch } = context;
  const { fetchEndpoint, fetchToken, validateStatus } = util;
  const { region, environment } = getters;

  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  // Slug is generated by the API
  delete payload.domain.slug;

  try {
    const { status } = await axios({
      method: 'patch',
      url: `${endpoint}/domains/update`,
      data: { clientToken, slug, domain },
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    await dispatch('getDomains', clientToken);
    await dispatch('getClients');

    await util.toast('success', `'${slug}' domain updated successfully`);
  } catch (error) {
    if (error.response.data.message === 'Unauthorized') {
      Swal.fire(
        'Unauthorized',
        'Sorry, you are not allowed to update a domain.',
        'error'
      );
      return;
    }

    Swal.fire('Error', error.response.data.message, 'error');
  }
};
