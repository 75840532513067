import Swal from 'sweetalert2';
import { json2csv } from 'json-2-csv';
import util from '../util';

/**
 * Export all clients to a CSV file.
 *
 * @param {Object} context Store context
 */
export default async () => {
  const { fetchDeploymentTargets, validateStatus, fetchToken } = util;

  await util.toast('warning', 'Generating export, please wait...');

  try {
    const euToken = fetchToken('eu-west-1');
    const usToken = fetchToken('us-east-1');
    const clients = [];

    const targets = fetchDeploymentTargets(['eu-west-1', 'us-east-1'], {
      'eu-west-1': ['staging', 'demo', 'prod'],
      'us-east-1': ['staging', 'demo', 'prod'],
    });

    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < targets.length; i += 1) {
      const target = targets[i];
      const isEuropean = target.includes('-eu');

      const accessToken = isEuropean ? euToken : usToken;
      const region = isEuropean ? 'eu' : 'us';

      const { status, data } = await axios({
        method: 'get',
        url: `${target}/list?limit=0`,
        validateStatus,
        headers: {
          Authorization: accessToken,
        },
      });

      // Check for expired tokens
      if (status === 401 || status === 403) {
        return;
      }

      // If something went wrong with the request
      if (!data.ok) {
        Swal.fire('Error', data.message, 'error');
        return;
      }

      // Append region to each client record
      for (let j = 0; j < data.clients.length; j += 1) {
        data.clients[j].region = region;
      }

      clients.push(...data.clients);
    }

    // Convert JSON to CSV file, and let the user download it
    json2csv(clients, (err, csv) => {
      if (err) {
        throw err;
      }

      const blob = new Blob([csv], { type: 'text/csv' });
      const anchor = document.createElement('a');

      anchor.download = 'skipsolabs-clients.csv';
      anchor.href = window.URL.createObjectURL(blob);
      anchor.style.display = 'none';

      document.body.appendChild(anchor);
      anchor.click();
    });
  } catch (error) {
    console.error(error.message);
    Swal.fire(
      'Error',
      'Unable to export clients. Contact the administrator.',
      'error'
    );
  }
};
