import Swal from 'sweetalert2';
import util from '../util';

/**
 * Load available domains for a single client
 *
 * @param {Object} context Store context
 * @param {String} clientToken Client (platform) token
 */
export default async (context, clientToken) => {
  const { getters, state } = context;
  const { isAuthenticated, region, environment } = getters;
  const { fetchEndpoint, fetchToken, validateStatus } = util;

  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  state.clientToken = clientToken;
  state.domains = [];
  state.domainsLoading = true;

  // If the current user is not authorized
  if (!isAuthenticated) {
    return;
  }

  try {
    const { status, data } = await axios({
      method: 'get',
      url: `${endpoint}/domains/get/${clientToken}`,
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    // If something went wrong with the request
    if (!data.ok) {
      Swal.fire('Error', data.message, 'error');
      return;
    }

    const domains = [];

    Object.entries(data.domains).forEach((domainInfo) => {
      // eslint-disable-next-line prefer-destructuring
      domains.push({ slug: domainInfo[0], ...domainInfo[1] });
    });

    state.domains = domains;
    state.domainsLoading = false;
  } catch (error) {
    console.error(error.message);
    Swal.fire(
      'Error',
      'Unable to load domains. Contact the administrator.',
      'error'
    );
  }
};
