import util from '../util';

/**
 * Get latest platform version
 *
 * @param {Object} context Store context
 */
export default async (context) => {
  const { getters, commit, state } = context;
  const { isAuthenticated, region } = getters;
  const { fetchEndpoint, fetchToken, validateStatus } = util;

  const endpoint = fetchEndpoint(region, 'prod');
  const accessToken = fetchToken(region);

  state.platformInfoLoading = true;

  // If the current user is not authorized
  if (!isAuthenticated) {
    return;
  }

  try {
    const { status, data } = await axios({
      method: 'get',
      url: `${endpoint}/platform/latest`,
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    commit('setPlatformInfo', data);
  } catch (error) {
    console.error(error.message);
    commit('setPlatformInfo', null);
  }
};
