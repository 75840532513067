<template>
  <div class="wrapper">
    <div
      class="swal2-icon swal2-question swal2-icon-show"
      style="display: flex"
    >
      <div class="swal2-icon-content">?</div>
    </div>
    <div class="content">
      Select project to update for<br />{{ project.name }} (<b
        >{{ project.environment }})</b
      >
    </div>
    <v-icon v-if="branches.length < 3" x-large color="blue-grey darken-2">
      mdi-call-split
    </v-icon>
    <div class="options">
      <v-btn
        v-for="branch in branches"
        :key="branch.value"
        class="ma-2"
        :color="branch.color"
        dark
        x-large
        :outlined="branch.value === 'styles'"
        :plain="branch.value === 'styles'"
        @click.prevent="emitValue(branch.value)"
      >
        {{ branch.label }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    branches: {
      type: Array,
      required: true,
    },
  },
  methods: {
    emitValue(value) {
      this.$emit('proceed', value);
    },
  },
};
</script>

<style scoped lang='scss'>
.wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  .content {
    height: auto;
    margin: 1rem;
    text-align: center;
  }
  .options {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 0.5rem 0.8rem;
    button {
      width: 110px;
    }
  }
  .mdi-call-split {
    transform: rotate(3.142rad);
  }
}
</style>