<template>
  <!-- TODO ADD ENV -->
  <v-row justify="center">
    <v-dialog v-model="showClientEditingDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit client</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label>
                    <b>Name</b>
                  </label>
                  <v-text-field v-model="form.name" placeholder="Name" />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Internal name</b>
                  </label>
                  <v-text-field
                    v-model="form.internalName"
                    placeholder="Internal name"
                    disabled
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Token</b>
                  </label>
                  <v-text-field
                    v-model="form.token"
                    placeholder="Token"
                    disabled
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Platform version</b>
                  </label>
                  <v-text-field
                    v-model="form.platformInfo.version"
                    placeholder="---"
                    disabled
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Platform branch</b>
                  </label>
                  <v-text-field
                    v-model="form.platformInfo.branch"
                    placeholder="---"
                    disabled
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Styles branch</b>
                  </label>
                  <v-text-field
                    v-model="form.stylesInfo.branch"
                    placeholder="---"
                    disabled
                  />
                </v-col>
                <v-col cols="12">
                  <label>
                    <b>Styles bundle</b>
                  </label>
                  <v-text-field
                    v-model="form.bundleName"
                    placeholder="---"
                    enabled
                  />
                </v-col>
                <details class="col col-11">
                  <summary><b>DB settings</b></summary>
                  <v-col v-if="showSettings()" cols="12">
                    <label>
                      <b>DB hostname</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.host"
                      placeholder="mydatabase.id.region.rds.amazonaws.com"
                    />
                  </v-col>
                  <v-col v-if="showSettings()" cols="12">
                    <label>
                      <b>DB username</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.user"
                      placeholder="dbusername"
                    />
                  </v-col>
                  <v-col v-if="showSettings()" cols="12">
                    <label>
                      <b>DB password</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.password"
                      :type="'text'"
                      placeholder="hidden"
                    />
                  </v-col>
                  <v-col v-if="showSettings()" cols="12">
                    <label>
                      <b>DB name</b>
                    </label>
                    <v-text-field
                      v-model="form.databaseCreds.name"
                      placeholder="dbname"
                    />
                  </v-col>
                </details>
                <v-col v-if="showSettings()" cols="12">
                  <label>
                    <b>Miscellaneous</b>
                  </label>
                  <v-checkbox
                    v-model="form.misc.msActive"
                    item-text="name"
                    item-value="value"
                    :label="`Microservices`"
                  />
                </v-col>
                <v-col v-if="showSettings()" cols="12">
                  <label>
                    <b>Background tasks</b>
                  </label>
                  <v-checkbox
                    v-model="form.tasks.scheduling"
                    item-text="name"
                    item-value="value"
                    :label="`Scheduling`"
                  />
                  <v-checkbox
                    v-model="form.tasks.recommendations"
                    item-text="name"
                    item-value="value"
                    :label="`Recommendations`"
                  />
                  <v-checkbox
                    v-model="form.tasks.tempUserRemoval"
                    item-text="name"
                    item-value="value"
                    :label="`Temp users removal (weekly)`"
                  />
                  <v-checkbox
                    v-model="form.tasks.gds.enabled"
                    item-text="name"
                    item-value="value"
                    :label="`Google Sheets sync`"
                  />
                  <v-checkbox
                    v-model="form.tasks.taskManager"
                    item-text="name"
                    item-value="value"
                    :label="`Task Manager Expiring Tasks Notifications`"
                  />
                  <v-checkbox
                    v-model="form.tasks.salesforce"
                    item-text="name"
                    item-value="value"
                    :label="`Salesforce sync`"
                  />
                  <v-col v-if="form.tasks.gds.enabled">
                    <label>
                      <b>Sync to Google each:</b>
                    </label>
                    <v-select
                      v-model="form.tasks.gds.syncInterval"
                      :items="taskIntervals"
                      label="Select an interval"
                      single-line
                    ></v-select>
                  </v-col>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="close">Close</v-btn>
          <v-btn color="red darken-1" text @click="remove">Delete</v-btn>
          <v-btn color="blue darken-1" text @click="update">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '../../event-bus';

export default {
  name: 'EditClientDialog',

  data() {
    return {
      form: this.getInitialForm(),

      taskIntervals: [
        { text: '1 minute', value: '1m' },
        { text: '15 minutes', value: '15m' },
        { text: '30 minutes', value: '30m' },
        { text: '1 hour', value: '1hr' },
        { text: '2 hours', value: '2hr' },
        { text: '6 hours', value: '6hr' },
        { text: '12 hours', value: '12hr' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      clients: 'clients',
      open: 'showClientEditingDialog',
    }),

    showClientEditingDialog: {
      get() {
        return this.open;
      },

      set(val) {
        this.$store.state.showClientEditingDialog = val;
        return val;
      },
    },
  },

  created() {
    EventBus.$on('toggleEdit', (token) => {
      const clientIndex = this.clients.findIndex((c) => c.token === token);

      this.form.token = token;

      if (clientIndex !== -1) {
        const client = { ...this.clients[clientIndex] };
        this.form = Object.assign(this.form, client);
      }
    });
  },

  methods: {
    ...mapActions(['deleteClient', 'updateClient']),

    close() {
      this.$store.commit('showClientEditingDialog', false);
    },

    showSettings() {
      return !this.form.internalName.includes('sk-internal-tasks');
    },

    getInitialForm() {
      return {
        token: '',
        name: '',
        internalName: '',
        databaseCreds: { host: null, user: null, password: null, name: null },
        domains: [
          {
            type: 'main',
            fqdns: [],
            name: null,
            slug: null,
            redirect: null,
            httpAuth: {
              enabled: false,
              username: null,
              password: null,
            },
            saml: false,
            idpConfig: {
              entityId: '',
              endpoint: '',
              singleLogoutService: '',
              publicCert: '',
            },
          },
        ],
        bundleName: '',
        misc: {
          msActive: false,
          httpAuth: {
            enabled: false,
            username: null,
            password: null,
          },
        },
        tasks: {
          recommendations: false,
          tempUserRemoval: false,
          gds: {
            enabled: false,
            syncInterval: '15m',
          },
          salesforce: false,
        },
        platformInfo: {
          branch: null,
          gitCommit: null,
          updatedAt: null,
          version: null,
        },
        stylesInfo: {
          branch: null,
          gitCommit: null,
          updatedAt: null,
        },
      };
    },

    async update() {
      await this.updateClient(this.form);

      this.$store.commit('showClientEditingDialog', false);
      this.form = this.getInitialForm();
    },

    async remove() {
      return Swal.fire({
        title: 'Delete this client?',
        icon: 'warning',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonColor: '#e64942',
      }).then((res) => {
        if (res.isConfirmed) {
          this.deleteClient(this.form.token);

          this.$store.commit('showClientEditingDialog', false);
          this.form = this.getInitialForm();
        }
      });
    },
  },
};
</script>

<style></style>
