<template>
  <v-navigation-drawer v-model="navigationDrawerState" dark :permanent="$vuetify.breakpoint.mdAndUp" left fixed
    class="main" touchless>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          Console
        </v-list-item-title>
        <v-list-item-subtitle>
          © {{ currentYear() }} SkipsoLabs
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list dense nav>
      <v-list-item to="/clients">
        <v-list-item-icon>
          <v-icon>mdi-earth</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Clients</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/microservices">
        <v-list-item-icon>
          <v-icon>mdi-chip</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Microservices</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :href="getProdUpdateFormURL" target="_blank">
        <v-list-item-icon>
          <v-icon>mdi-refresh</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Request a prod update</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list-item link href="https://contacts.google.com/directory" target="_blank">
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Team users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/analytics">
        <v-list-item-icon>
          <v-icon>mdi-google-analytics</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Analytics</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link href="https://knowledge.skipsolabs.com" target="_blank">
        <v-list-item-icon>
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Knowledge base</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
    </v-list>

    <v-list v-if="currentRouteName == 'Clients'" nav>
      <v-list-item>
        <v-select v-model="selectedRegion" placeholder="Region" :items="visibleRegions" />
      </v-list-item>

      <v-list-item>
        <v-select v-model="selectedEnvironment" placeholder="Environment" :items="visibleEnvironments" />
      </v-list-item>

      <v-list v-if="platformInfoLoading">
        <v-list-item>
          <v-progress-circular indeterminate color="white" />
        </v-list-item>
      </v-list>

      <v-list v-else-if="platformInfo">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Latest platform version:
              <p>
                <a style="color: inherit" :href="`${platformInfo[0].url}`" target="_blank">{{ platformInfo[0].version
                }}</a>
              </p>
            </v-list-item-title>
            <v-list-item-subtitle>Released on
              {{ formatDate(platformInfo[0].date) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <sk-changelog :changelog="platformInfo" />
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-title> Unable to get platform info </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-list>

    <v-list v-if="currentRouteName == 'Microservices'" nav>
      <v-list-item>
        <v-select v-model="selectedRegion" placeholder="Region" :items="visibleRegions" />
      </v-list-item>

      <v-list-item>
        <v-select v-model="selectedEnvironment" placeholder="Environment" :items="visibleEnvironments" />
      </v-list-item>

      <v-list v-if="platformInfoLoading">
        <v-list-item>
          <v-progress-circular indeterminate color="white" />
        </v-list-item>
      </v-list>

      <v-list v-else-if="platformInfo">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Latest platform version:
              <p>
                <a style="color: inherit" :href="`${platformInfo[0].url}`" target="_blank">{{
                  platformInfo[0].version
                }}</a>
              </p>
            </v-list-item-title>
            <v-list-item-subtitle>Released on
              {{ formatDate(platformInfo[0].date) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <sk-changelog :changelog="platformInfo" />
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-title> Unable to get platform info </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-list>

    <v-list v-if="currentRouteName == 'Analytics'" nav>
      <v-list-item>
        <v-select placeholder="Application" :items="analyticsSources" :value="analyticsSources[0]"
          @input="setSelectedAnalytics($event)" />
      </v-list-item>
    </v-list>

    <v-list class="bottom-list" dense nav>
      <v-divider />
      <v-list-item link @click="signOut">
        <v-list-item-icon>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="pa-2">Sign out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import SkChangelog from '@/components/SkChangelog.vue';
import util from '../store/actions/util';

const { fetchEmail } = util;

export default {
  name: 'SkNavigationDrawer',

  // Inner components
  components: {
    SkChangelog
  },
  props: {
    showDrawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedEnvironment: this.environment,
      selectedRegion: this.region,
    };
  },

  computed: {
    ...mapState(['selectedAnalytics', 'analyticsSources']),
    ...mapGetters([
      'isAuthenticated',
      'regions',
      'environments',
      'region',
      'environment',
      'platformInfo',
      'platformInfoLoading',
    ]),

    /**
     * Get current Route name
     */
    currentRouteName() {
      return this.$route.name;
    },

    getProdUpdateFormURL() {
      const AIRTABLE_URL = `https://airtable.com/shrPQXcTiL88Czj7R?prefill_Email=`;
      const currentEmail = fetchEmail();

      return `${AIRTABLE_URL}${currentEmail}`;
    },

    visibleRegions() {
      return Object.keys(this.regions).map((region) => ({
        text: this.regions[region],
        value: region,
      }));
    },

    visibleEnvironments() {
      const envs = { ...this.environments };

      return Object.keys(envs).map((env) => ({ text: envs[env], value: env }));
    },

    // open or close the drawer based on the showDrawer prop
    // emit drawer value when page changes
    navigationDrawerState: {
      // getter
      get() {
        return this.showDrawer;
      },
      // setter
      set(newValue) {
        this.$emit('close:drawer', newValue);
      },
    },
  },

  watch: {
    selectedRegion(newValue) {
      this.updateRegion(newValue);
    },

    selectedEnvironment(newValue) {
      this.updateEnv(newValue);
    },

    analyticsSources(newValue) {
      this.setSelectedAnalytics(newValue);
    },
  },

  created() {
    this.selectedRegion = this.region;
    this.selectedEnvironment = this.environment;

    this.platformInfo.name = '---';
    this.getPlatformInfo();

    // Refresh data every 2 minutes
    setInterval(() => this.getPlatformInfo(), 120000);
  },

  methods: {
    ...mapActions(['signOut', 'getClients', 'getMicroservices', 'getPlatformInfo']),

    ...mapMutations(['setRegion', 'setEnvironment', 'setSelectedAnalytics']),

    updateRegion(region) {
      this.setRegion(region);
      this.getClients();
      this.getMicroservices();
    },

    updateEnv(env) {
      this.setEnvironment(env);
      this.getClients();
      this.getMicroservices();
    },

    formatDate(datetime) {
      const localizedFormat = require('dayjs/plugin/localizedFormat');
      dayjs.extend(localizedFormat);

      return `${dayjs(datetime).format('lll')}`;
    },

    /**
     * Return the current year
     */
    currentYear() {
      return `${dayjs().format('YYYY')}`;
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer__content {
  overflow: hidden;
}

.bottom-list {
  width: 100%;
  position: absolute !important;
  bottom: 0 !important;
}
</style>
