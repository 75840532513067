import Swal from 'sweetalert2';
import util from '../util';

/**
 * Load available clients
 *
 * @param {Object} context Store context
 */
export default async (context) => {
  const { getters, commit, state } = context;
  const {
    isAuthenticated,
    isAutoRefreshing,
    region,
    environment,
    resultsPerPage,
  } = getters;
  const { fetchEndpoint, fetchToken, validateStatus } = util;

  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  state.clientsLoading = true;

  // If the current user is not authorized
  if (!isAuthenticated) {
    return;
  }

  // Reset options only when refreshing manually
  if (!isAutoRefreshing) {
    commit('resetClientsOptions');
  }

  try {
    const { status, data } = await axios({
      method: 'get',
      url: `${endpoint}/list`,
      validateStatus,
      params: {
        limit: 0,
      },
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    // If something went wrong with the request
    if (!data.ok) {
      Swal.fire('Error', data.message, 'error');
      return;
    }

    const { clients } = data;

    state.endOfResults = Math.ceil(clients.length / resultsPerPage);
    commit('setClients', clients);
  } catch (error) {
    console.error(error.message);
    Swal.fire(
      'Error',
      'Unable to load clients. Contact the administrator.',
      'error'
    );
  }
};
