import authCreds from './creds';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

const signOut = async (userPool) => {
  const user = userPool.getCurrentUser();

  if (user) {
    user.signOut();
  }
};

/**
 * Sign out the current user.
 *
 * @param {Object} context Store context
 */
export default async (context) => {
  const { commit } = context;
  const { UserPoolId, ClientId } = authCreds;

  const poolData = { UserPoolId, ClientId };
  const credentials = new AWS.CognitoIdentityCredentials(authCreds);
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  AWS.config.update({ region: 'eu-west-1', credentials });

  commit('resetClientConfig');

  try {
    await signOut(userPool);
  } catch (error) {
    await swal('Error', error.message, 'error');
  } finally {
    location.href = '/';
  }
};
