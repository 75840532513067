import authActions from './auth/index';
import clientsActions from './clients/index';
import domainsActions from './domains/index';
import platformActions from './platform/index';
import microservicesActions from './microservices/index';
import samlActions from './saml/index';

export default {
  ...authActions,
  ...clientsActions,
  ...domainsActions,
  ...platformActions,
  ...microservicesActions,
  ...samlActions,
};
