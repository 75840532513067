import getDomains from './get';
import deleteDomain from './delete';
import createDomain from './create';
import updateDomain from './update';
import deleteIdPConfig from './delete-idp-config';

export default {
  getDomains,
  deleteDomain,
  createDomain,
  updateDomain,
  deleteIdPConfig,
};
