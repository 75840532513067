<template>
  <div class="content">
    <article v-if="!analyticsLoaded" class="sk-loader">
      <v-progress-circular
        class="sk-load-icon"
        indeterminate
        :size="150"
        :width="10"
        color="primary"
      />
    </article>

    <iframe
      v-show="analyticsLoaded"
      :src="selectedAnalytics"
      width="100%"
      height="2250"
      frameborder="0"
      style="border: 0"
      allowfullscreen
      @load="onLoad"
    ></iframe>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  // Component data
  data() {
    return {
      analyticsLoaded: false,
    };
  },

  // Computed props
  computed: {
    ...mapState(['selectedAnalytics']),
    ...mapGetters([
      'resultsPage',
      'endOfResults',
      'resultsPerPage',
      'isAuthenticated',
    ]),
  },

  watch: {
    selectedAnalytics() {
      this.analyticsLoaded = false;
    },
  },

  // Methods
  methods: {
    onLoad() {
      setTimeout(() => {
        this.analyticsLoaded = true;
      }, 3500);
    },
  },
};
</script>

<style>
.sk-loader {
  width: 100%;
  height: 80vh;

  padding: 10px;
  box-sizing: border-box;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999 !important;
}
</style>
