import Swal from 'sweetalert2';
import util from '../util';

/**
 * Delete client.
 *
 * @param {Object} context Store context
 * @param {String} token Client token
 * @return {Promise}
 */
export default async (context, token) => {
  const { dispatch, getters } = context;
  const { fetchEndpoint, fetchToken, validateStatus } = util;
  const { region, environment } = getters;
  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  try {
    const { status } = await axios({
      method: 'delete',
      url: `${endpoint}/delete/${token}`,
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    await util.toast('success', 'The client has been successfully removed');

    await dispatch('getClients');
  } catch (error) {
    if (error.response && error.response.data.message === 'Unauthorized') {
      Swal.fire(
        'Unauthorized',
        'Sorry, you are not allowed to delete a client.',
        'error'
      );
    } else {
      console.error(error.message);
      Swal.fire(
        'Error',
        'Unable to delete a client. Contact the administrator.',
        'error'
      );
    }
  }
};
