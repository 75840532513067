<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isAuthenticated']),
  },

  mounted() {
    // TODO: Add support to proper retrieval of the refresh token (if available)
    const idToken = this.$route.fullPath.split('&')[1].replace('id_token=', '');

    this.OAuthSignIn({
      accessToken: idToken,
      region: 'eu-west-1',
      environment: 'staging',
    });
  },

  methods: {
    ...mapActions(['OAuthSignIn']),
  },
};
</script>
