import Swal from 'sweetalert2';
import util from '../util';

/**
 * Delete a domain for a specific client.
 *
 * @param {Object} context Store context
 * @param {Object} payload Client token and slug for the domain to delete
 * @return {Promise}
 */
export default async (context, payload) => {
  const { dispatch, getters } = context;
  const { fetchEndpoint, fetchToken, validateStatus } = util;
  const { region, environment } = getters;
  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  const { clientToken, slug } = payload;

  try {
    const { status } = await axios({
      method: 'delete',
      url: `${endpoint}/domains/delete`,
      data: { clientToken, slug },
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    await dispatch('getDomains', clientToken);
    await dispatch('getClients');

    await util.toast(
      'success',
      `'${slug}' domain has been removed successfully`
    );
  } catch (error) {
    if (error.response.data.message === 'Unauthorized') {
      Swal.fire(
        'Unauthorized',
        'Sorry, you are not allowed to delete a domain.',
        'error'
      );
      return;
    }

    Swal.fire('Error', error.response.data.message, 'error');
  }
};
