<template>
  <div class="wrapper">
    <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex">
      <div class="swal2-icon-content">!</div>
    </div>
    <div class="content">
      <h2>Are you sure?</h2>
      <br />
      <p v-if="environment === 'prod'">Type 'Confirm' to verify this action</p>
      <v-text-field
        v-if="environment === 'prod'"
        v-model="confirmationDialogue"
        :rules="requiredRules"
        label="Confirm"
        placeholder="Type confirm to verify this action"
        outlined
        required
      ></v-text-field>
      <span v-if="!updatePlatform"
        >Continue switching <samp>{{ selectedProject }}</samp> project<br />to
        <samp>{{ branch }}</samp> for <br />{{ project.name }} (<b>{{
          project.environment
        }}</b
        >)?
      </span>
      <span v-else>
        Continue updating <samp>{{ selectedProject }}</samp> for <br />{{
          project.name
        }}
        (<b>{{ project.environment }}</b
        >)?
      </span>
    </div>
    <div class="options">
      <v-btn class="ma-2" x-large color="primary" dark @click.prevent="proceed">
        Confirm
      </v-btn>
      <v-btn class="ma-2" x-large color="red" dark @click.prevent="close">
        Cancel
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    selectedProject: {
      type: String,
      required: true,
    },
    branch: {
      type: String,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    updatePlatform: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      confirmationDialogue: '',
      requiredRules: [
        (v) => !!v || 'Field is required',
        (v) => v === 'Confirm' || `Type 'Confirm' to verify this field`,
      ],
    };
  },
  computed: {
    ...mapGetters(['environment']),
    validForm() {
      return (
        (this.environment === 'prod' &&
          this.confirmationDialogue === 'Confirm') ||
        this.environment !== 'prod'
      );
    },
  },
  methods: {
    proceed() {
      if (!this.validForm) return;
      this.$emit('proceed');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang='scss'>
.wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;

  .content {
    height: auto;
    margin: 1rem;
    text-align: center;
  }
  .options {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem 0.8rem;
  }
}
</style>