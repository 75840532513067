<template>
  <sk-modal
    component-id="1"
    :escape-button-close="true"
    :backdrop="true"
    @close="close"
  >
    <div class="modal-header">
      <button
        class="modal-back"
        @click.prevent="modalSteps > 0 ? modalSteps-- : (modalSteps = 0)"
      >
        <v-icon v-if="modalSteps" large> mdi-arrow-left </v-icon>
      </button>
      <button class="modal-close" @click.prevent="close">
        <v-icon large> mdi-close </v-icon>
      </button>
    </div>
    <SwitchBranchStep1
      v-if="modalSteps === 0"
      :branches="branches"
      :project="project"
      @proceed="selectProject"
    />
    <SwitchBranchStep2
      v-if="modalSteps === 1"
      :project="project"
      :selected-project="selectedProject"
      @proceed="selectBranch"
    />
    <SwitchBranchStep3
      v-if="modalSteps === 2"
      :project="project"
      :selected-project="selectedProject"
      :branch="selectedBranch"
      @proceed="submitProject"
      @close="close"
    />
  </sk-modal>
</template>

<script>
import SkModal from '@skipsolabs/vue-library/src/components/SkModal/SkModal.vue';
import SwitchBranchStep1 from './Modal.step1.vue';
import SwitchBranchStep2 from './Modal.step2.vue';
import SwitchBranchStep3 from './Modal.step3.vue';

export default {
  components: {
    SwitchBranchStep1,
    SwitchBranchStep2,
    SwitchBranchStep3,
    SkModal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalSteps: 0,
      selectedProject: null,
      selectedBranch: '',
      branches: [
        {
          label: 'Platform',
          color: 'primary',
          value: 'platform',
        },
        {
          label: 'Styles',
          color: 'primary',
          value: 'styles',
        },
      ],
    };
  },
  methods: {
    selectBranch(val) {
      this.selectedBranch = val;
      this.modalSteps = 2;
    },
    selectProject(val) {
      this.selectedProject = val;
      this.modalSteps = 1;
    },
    submitProject() {
      const obj = {
        name: this.selectedProject,
        branch: this.selectedBranch,
      };
      this.$emit('submitProject', obj);
      this.close();
    },
    close() {
      this.$emit('close');
      this.modalSteps = 0;
    },
  },
};
</script>

<style>
</style>