import DashboardLayout from '../pages/Layout/DashboardLayout.vue';

import Clients from '../pages/Clients.vue';
import Microservices from '../pages/Microservices.vue';
import SignIn from '../pages/SignIn.vue';
import OAuthLogin from '../pages/OAuthLogin.vue';
import Analytics from '../pages/Analytics.vue';

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'signin',
        name: 'Sign in',
        component: SignIn,
      },
      {
        path: 'oauth',
        name: 'OAuth',
        component: OAuthLogin,
      },
      {
        path: 'clients',
        name: 'Clients',
        component: Clients,
      },
      {
        path: 'microservices',
        name: 'Microservices',
        component: Microservices,
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: Analytics,
      },
      {
        path: '*',
        component: Clients,
      },
    ],
  },
];

export default routes;
