<template>
  <v-row justify="center">
    <v-dialog width="600px" @click:outside="search = ''">
      <template #activator="{ on, attrs }">
        <v-btn
          color="white"
          small
          outlined
          v-bind="attrs"
          style="margin-top: 1vh"
          v-on="on"
        >
          Changelog
        </v-btn>
      </template>
      <v-card class="changelog">
        <v-card-title>
          <v-row>
            <v-col class="text-h5 d-flex align-center" cols="12" sm="6">
              Platform changelog
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text v-for="item in changelogSearch" :key="item.version">
          <section class="clog-item">
            <div class="clog-item__header">
              <p>
                <strong class="clog-item__header__nr"
                  >{{ item.version }}
                </strong>
                <!-- <span
                  :class="[
                    'clog-item__header__type',
                    getReleaseType(item.message),
                  ]"
                >
                  {{ getCommitType(item.message) }}
                </span> -->
              </p>
              <a :href="item.url" class="clog-item__header__date"
                >{{ item.date | diffForHumans }}
              </a>
            </div>
            <ul class="clog-item__body">
              <li>
                <div v-html="renderMarkdown(item.message)"></div>
              </li>
            </ul>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import { marked } from 'marked';

export default {
  name: 'SkChangelog',
  filters: {
    diffForHumans: (date) => {
      const localizedFormat = require('dayjs/plugin/localizedFormat');
      dayjs.extend(localizedFormat);
      if (!date) {
        return null;
      }

      return dayjs(date).format('YYYY-MM-DD HH:mm');
    },
  },
  props: {
    changelog: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    changelogSearch() {
      if (this.search.length > 0) {
        return this.changelog.filter((item) => {
          return (
            item.version.toLowerCase().includes(this.search.toLowerCase()) ||
            item.message.toLowerCase().includes(this.search.toLowerCase()) ||
            item.date.includes(this.search)
          );
        });
      }

      return this.changelog;
    },
  },
  methods: {
    renderMarkdown(message) {
      return marked(message);
    },

    getCommitType(message) {
      return message.split('\n\n')[0].replace('### ', '');
    },

    // getReleaseType(message) {
    //   const labelTypes = {
    //     'Bug fixes': 'fix',
    //     Features: 'feat',
    //     Documentation: 'docs',
    //     Styles: 'styles',
    //     'Code Refactoring': 'refactor',
    //     Performance: 'perf',
    //     'CI Pipeline': 'ci',
    //     'Build System': 'build',
    //     Tests: 'test',
    //     Miscellaneous: 'chore',
    //     Revert: 'revert',
    //   };

    //   return labelTypes[this.getCommitType(message)];
    // },
  },
};
</script>

<style lang="scss" scoped>
.changelog {
  min-height: 90vh;
  position: relative;
}
.clog-item {
  background-color: white;
  position: relative;
  margin: 0.5rem;
  padding: 1rem 0.5rem !important;
  padding-top: 0 !important;
  border-bottom: 0.5px solid #eeeeee;
  &:last-child {
    margin-bottom: 0;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__nr {
      font-size: clamp(1.4rem, 1.5vw, 1.3rem);
      color: #000;
      font-weight: bold;
    }

    &__type {
      font-size: clamp(0.65rem, 1.5vw, 0.75rem);
      font-weight: 600;
      text-transform: capitalize;
      margin-left: 10px;
      padding: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__date {
      font-size: clamp(0.7rem, 1.5vw, 0.8rem);
      color: rgb(27, 27, 27);
      font-weight: 600;
      padding: 5px;
      display: block;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;

      &:hover {
        //add box hover effect to link
        background-color: whitesmoke;
        text-decoration: none;
      }
    }
  }
  &__body {
    font-size: clamp(0.9rem, 1.5vw, 1rem);
    color: #000;
    list-style-type: none;
    padding: 10px 0 5px 5px;
    &__message {
      color: gray;
      margin-top: 10px;
    }

    &__link {
      font-weight: 600;
      text-decoration: none;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        scale: 1.1;
      }
    }
  }
}

.v-dialog > .v-card > .v-card__text {
  padding: 0 !important;
}
</style>

<style lang="scss">
// change type
$types: (
  'fix': hsl(0, 100%, 50%),
  'feat': hsl(187, 100%, 42%),
  'docs': hsl(207, 90%, 54%),
  'styles': hsl(36, 100%, 50%),
  'refactor': hsl(54, 97%, 28%),
  'perf': hsl(14, 100%, 57%),
  'build': hsl(174, 100%, 29%),
  'ci': hsl(174, 100%, 29%),
  'tests': hsl(262, 52%, 47%),
  'chore': hsl(291, 64%, 42%),
  'revert': hsl(16, 25%, 38%),
);

//Loop over Initials Sizes
@each $name, $color in $types {
  .#{$name} {
    color: #{$color};
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #{$color};
      opacity: 0.15;
      border-radius: 5px;
    }
  }
}

.clog-item__body__message {
  p {
    width: 100%;
    border-top: 0.3px solid #ccc;
    font-size: 0.9rem;
    margin: 5px 0 !important;
    color: rgb(109, 108, 109);
    font-weight: 600;
    padding: 6px 0px;
    display: inline-flex;
  }
}
</style>
