import Swal from 'sweetalert2';
import util from '../util';

/**
 * Create the queue job to generate IdP certificate for a specific client.
 *
 * @param {Object} context Store context
 * @param {Object} payload Client token and slug for the domain
 * @return {Promise}
 */
export default async (context, payload) => {
  const { dispatch, getters } = context;
  const { fetchEndpoint, fetchToken, validateStatus } = util;
  const { region, environment } = getters;
  const endpoint = fetchEndpoint(region, environment);
  const accessToken = fetchToken(region);

  const { clientToken, slug } = payload;

  try {
    const { status } = await axios({
      method: 'post',
      url: `${endpoint}/saml/generate/idp/cert`,
      data: { clientToken },
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    await dispatch('getDomains', clientToken);
    await dispatch('getClients');

    await util.toast(
      'success',
      `'${slug}' domain IdP certificate has been added into queue for processing. 
      Please wait for a few minutes and refresh the page for the certificate to be shown in the modal.`
    );
  } catch (error) {
    console.log(error);
    if (error.response.data.message === 'Unauthorized') {
      Swal.fire(
        'Unauthorized',
        'Sorry, you are not allowed to generate an IdP certificate.',
        'error'
      );
      return;
    }

    Swal.fire('Error', error.response.data.message, 'error');
  }
};
